
:root:root {
  --bg-color: #ececec;
  --theme-radius: 8px;
  --theme-color: #ec8046;
  --theme-dark-color: #4a1c03;
  --border-color: #efefef;
  --adm-color-primary: #ec8046;
  --page-height: 100vh;
}
html {
  background-color: var(--bg-color);
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,
body,
#root {
  // min-height: 100vh;
  font-size: 14px;
}
#root {
  max-width: 750px;
  margin: 0 auto;
}

input,
button,
textarea {
  appearance: none;
  font-size: 14px;
  box-sizing: border-box;
  outline: none;
}
article,
section,
aside,
main,
header,
footer,
div,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
li,
p {
  display: block;
  box-sizing: border-box;
  margin: 0;
}
ul {
  padding-left: 0;
  list-style: none;
}

.toast-container {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  &.hide {
    animation: toastHide ease-out 5s;
    animation-fill-mode: forwards;
  }
  .toast-content {
    color: #fff;
    border-radius: 6px;
    padding: 15px;
    margin: 10%;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.7);
    &.fail {
      background: rgba(0, 0, 0, 0.7) url('./assets/svg/warning.svg') center 15px
        no-repeat;
      width: 130px;
      height: 130px;
      padding-top: 50px;
    }
    &.success {
      background: rgba(0, 0, 0, 0.7) url('./assets/svg/success.svg') center 15px
        no-repeat;
      width: 130px;
      height: 130px;
      padding-top: 50px;
    }
  }
}

@keyframes toastHide {
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
